#skills {
  background: linear-gradient(
      38.73deg,
      rgba(204, 0, 187, 0.15) 0%,
      rgba(201, 32, 184, 0) 50%
    ),
    linear-gradient(
      141.27deg,
      rgba(0, 70, 209, 0) 50%,
      rgba(0, 70, 209, 0.15) 100%
    );
  clip-path: var(--custom-clip-path);
}

#skills img {
  width: 40px;
  height: 40px;
  display: block;
  margin: auto;
}

.skill-item {
  transition: transform 0.3s ease;
}

.skill-item:hover {
  cursor: pointer;
  box-shadow: 0px 3px 6px 0px rgba(0, 255, 255, 0.7);
  transform: translateY(-2px);
}
