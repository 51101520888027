.projects-container {
  background: linear-gradient(
    343.07deg,
    rgba(132, 59, 206, 0.06) 5.71%,
    rgba(132, 59, 206, 0) 64.83%
  );
}

.project-card {
  background-color: var(--bg);
  cursor: pointer;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 12px 4px;
  transition: transform 0.5s ease;
}

.project-card:hover {
  box-shadow: 0px 0px 10px var(--custom-primary);
  transform: translateY(-5px);
}

.projects-container p,
.project-card p {
  color: var(--custom-text-secondary);
}

.project-card img {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 16px 2px;
}

.icon {
  background-color: #111;
  color: #fff;
  padding: 5px;
  border-radius: 50%;
  font-size: 1.3rem;
}

.icon:hover {
  background-color: #ddd;
  color: #111;
}
